import logo from './wortlogo.png'
import './Respooler.css'
import { Box, Grid } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { RespoolerParameters } from './RespoolerParameters'
import { GCodeGenerator } from './GCodeGenerator'
import { RecoilLocalStoreInterface, loadStateFromLocalStorage } from './RecoilLocalStoreInterface'
import { RecoilRoot } from 'recoil'
import { SnackbarProvider } from 'notistack'

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        text: { primary: '#eee' },

        primary: {
            dark: '#F3933F',
            main: '#F3933F'
        },
        background: { paper: 'rgba(34,34,34,.9)' }
    }
})

const possibleFilamentUnits = [
    {
        value: 'Meters',
        label: 'm',
    },
    {
        value: 'Grams',
        label: 'g',
    }
]

export const Respooler: React.FC = () => {
    return <SnackbarProvider>
        <RecoilRoot initializeState={loadStateFromLocalStorage}>
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />

                <RecoilLocalStoreInterface />
                <Box sx={{ display: 'flex', width: '100vw', height: '100vh', alignItems: 'center', justifyContent: 'center', flexGrow: 1 }}>
                    <Grid sx={{
                        maxWidth: '1000px',
                    }} container spacing={2} >
                        <Grid item xs={12}>
                            <img style={{ maxHeight: '60px' }} src={logo} alt="logo" />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <RespoolerParameters />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <GCodeGenerator />
                        </Grid>
                    </Grid>
                </Box>
            </ThemeProvider>
        </RecoilRoot>
    </SnackbarProvider>

}

