import _ from 'lodash'
import React, { Fragment, useEffect } from 'react'
import { RecoilRoot, atom, atomFamily, useSetRecoilState, useRecoilSnapshot, RecoilRootProps, MutableSnapshot, useResetRecoilState } from 'recoil'
import { parametersSelector, getRespoolerParametersAtom } from './RespoolerParameters'
import { useSnackbar } from 'notistack'
import { Button } from '@mui/material'


export const RecoilLocalStoreInterface: React.FC = () => {
    const snapshot = useRecoilSnapshot()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const resetParameters = useResetRecoilState(getRespoolerParametersAtom({}))
    useEffect(() => {
        // store to local storage on every change
        const asyncInternal = async () => {

            const parameters = await snapshot.getPromise(parametersSelector)
            console.log(parameters)
            /*
            for (const node of snapshot.getNodes_UNSTABLE()) {
                console.debug(node.key, snapshot.getLoadable(node).contents)
            }
            */
            localStorage.setItem("parameters", JSON.stringify(parameters))
        }
        asyncInternal()
    }, [snapshot])

    const deleteLocalStorage = () => {
        localStorage.removeItem('parameters')
        resetParameters()
    }

    // customized
    const action = (key: any) => (
        <Fragment>
            <Button onClick={() => {
                deleteLocalStorage()
                closeSnackbar(key)
            }}>
                Reset
            </Button>
        </Fragment>
    )



    useEffect(() => {
        const data = localStorage.getItem('parameters')
        if (_.isNil(data)) return

        enqueueSnackbar('restored parameter data from local storage', {
            autoHideDuration: 5000,
            action
        })
    }, [])
    return <></>
}


export const loadStateFromLocalStorage = (snapshot: MutableSnapshot): void => {
    const data = localStorage.getItem('parameters')
    if (_.isNil(data)) return


    const getInitialAtom = getRespoolerParametersAtom({})

    const parsed = JSON.parse(data)

    snapshot.set(getInitialAtom, parsed)
    snapshot.set(getRespoolerParametersAtom({}), parsed)
}
