import { TextField, InputAdornment, Stack, ToggleButton, ToggleButtonGroup, Card, CardContent, Button, CardActions, Collapse, FormControl, InputLabel, Select, SelectChangeEvent, MenuItem } from "@mui/material"
import { useEffect, useRef } from "react"
import { useRecoilValue, atomFamily, useRecoilState, useRecoilCallback, useRecoilSnapshot, selector } from "recoil"
import { InternalParameters, internalParameterDefaults } from './InternalParameters'
import _ from 'lodash'

export interface RespoolerParameters extends Spool, InternalParameters {
    desiredAmount: number
    desiredAmountUnit: 'g' | 'm'
    filamentDiameter: number
}

export type Spool = {
    spoolCoreDiameter: number,
    spoolOuterDiameter: number,
    spoolInnerWidth: number,
    spoolPresetName: string
}


export const spoolPresets: Array<Spool> = [
    {
        spoolPresetName: 'Spool 1',
        spoolCoreDiameter: 100,
        spoolOuterDiameter: 200,
        spoolInnerWidth: 40
    }, {
        spoolPresetName: 'Spool 2',
        spoolCoreDiameter: 50,
        spoolOuterDiameter: 300,
        spoolInnerWidth: 80
    }, {
        spoolPresetName: 'Spool 3',
        spoolCoreDiameter: 200,
        spoolOuterDiameter: 300,
        spoolInnerWidth: 40
    }
]



export const respoolerParametersDefault: RespoolerParameters = {
    ...spoolPresets[0],
    ...internalParameterDefaults,
    desiredAmount: 100,
    desiredAmountUnit: 'g',
    filamentDiameter: 1.75
}
/* 
* here we use atomFamily as a faux singleton 
* when no additional input property is given (the empty {} object)
* the atomFamily() func will always return the same atom
* this way every instance of selectSpoolPreset has the same atom for state
* this is bad for reusability but is desireable in our specific case
*/
export const getRespoolerParametersAtom = atomFamily<RespoolerParameters, {}>({
    key: 'respoolerParameterSingleton',
    default: respoolerParametersDefault
})


export const parametersSelector = selector({
    key: "Parameters",
    get: ({ get }) => {
        const parameters = get(getRespoolerParametersAtom({}))
        return parameters
    }
})



export const RespoolerParameters: React.FC = () => {


    // see above, if we give an empty object as identifier // the same identifier, we always get the same atom
    // this acts as an effective global singleton without writing one / using a custom memoizer
    const [respoolerParameters, setRespoolerParameters] = useRecoilState(getRespoolerParametersAtom({}))

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRespoolerParameters(prev => {
            return {
                ...prev,
                //[e.target.name]: _.max([e.target.value, 0.1])
                [e.target.name]: _.toNumber(e.target.value)
            }
        })
    }

    return <Stack spacing={2}>
        <Card variant="outlined">
            <CardContent>
                <Stack spacing={2}>
                    <Stack direction={'row'} spacing={1}>
                        <TextField
                            sx={{ width: '100%' }}
                            label="Desired Filament Amount"
                            variant="standard"
                            value={respoolerParameters.desiredAmount}
                            name="desiredAmount"
                            onChange={handleChange}
                        >
                        </TextField>

                        <ToggleButtonGroup
                            value={respoolerParameters.desiredAmountUnit}
                            exclusive
                            onChange={(event: React.MouseEvent<HTMLElement>, newUnit: RespoolerParameters['desiredAmountUnit'],) => {
                                setRespoolerParameters(prev => {
                                    return {
                                        ...prev,
                                        desiredAmountUnit: newUnit
                                    }
                                })
                            }}
                            aria-label="text alignment"
                        >
                            <ToggleButton value="m" aria-label="left aligned">
                                m
                            </ToggleButton>
                            <ToggleButton value="g" aria-label="right aligned">
                                g
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>


                    <FormControl variant="standard" style={{ marginTop: 20, marginBottom: 10 }}>
                        <InputLabel>Select Spool Preset</InputLabel>
                        <Select
                            value={respoolerParameters.spoolPresetName}
                            onChange={(e: SelectChangeEvent<string>) => {
                                if (e.target.value !== respoolerParameters.spoolPresetName) {
                                    let preset = _.filter(spoolPresets, p => p.spoolPresetName == e.target.value)
                                    if (preset.length > 0) {
                                        setRespoolerParameters(prev => {
                                            return {
                                                ...prev,
                                                ..._.clone(_.clone(preset[0]))
                                            }
                                        })
                                    }
                                    //
                                }
                            }}
                        >
                            {
                                _.map(spoolPresets, spoolPreset => {
                                    return <MenuItem key={spoolPreset.spoolPresetName} value={spoolPreset.spoolPresetName}>{spoolPreset.spoolPresetName}</MenuItem>
                                })
                            }
                        </Select>
                    </FormControl>


                    <TextField
                        label="Spool Core Diameter"
                        type="number"
                        variant="standard"
                        value={respoolerParameters.spoolCoreDiameter}
                        name="spoolCoreDiameter"
                        onChange={handleChange}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">mm</InputAdornment>,
                        }}
                    />
                    <TextField
                        label="Spool Outer Diameter"
                        variant="standard"
                        type="number"
                        name="spoolOuterDiameter"
                        onChange={handleChange}
                        value={respoolerParameters.spoolOuterDiameter}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">mm</InputAdornment>,
                        }}
                    />
                    <TextField
                        label="Spool Inner Width"
                        variant="standard"
                        type="number"
                        name="spoolInnerWidth"
                        onChange={handleChange}
                        value={respoolerParameters.spoolInnerWidth}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">mm</InputAdornment>,
                        }}
                    />
                    <TextField
                        label="Filament Diameter"
                        variant="standard"
                        type="number"
                        name="filamentDiameter"
                        onChange={handleChange}
                        value={respoolerParameters.filamentDiameter}
                        InputProps={{
                            endAdornment: <InputAdornment position="start">mm</InputAdornment>,
                        }}
                    />
                </Stack>
            </CardContent>
        </Card>




        <InternalParameters />
    </Stack>

}