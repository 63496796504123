import { Stack, Card, CardContent, TextareaAutosize } from "@mui/material"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useRecoilValue } from "recoil"
import { RespoolerParameters, getRespoolerParametersAtom, spoolPresets } from './RespoolerParameters'




const generateGCode = (params: RespoolerParameters) => {

    let valid = true
    /*
    _.forOwn(params, (key, value) => {
        console.log(`${value}: ${key}`)
        //@ts-ignore
        if (_.isNil(key) || key < 0.1 || key === '') valid = false
    })
    */

    //exit if any value is invalid
    if (!valid) return 'check params'


    //destructure everything
    const {
        desiredAmount,
        filamentDensity,
        maximumSpeed,
        desiredAmountUnit,
        filamentDiameter,
        spoolCoreDiameter,
        spoolInnerWidth,
        spoolOuterDiameter,
        tightpackingCorrection,
        unitsPerSpoolRev
    } = params

    let gcode = ''

    const addComment = (text: string = '') => {
        gcode += `; ${text} \n`
    }

    const addLine = (text: string = '', comment?: string) => {
        if (!_.isNil(comment)) {
            gcode += `${text} ; ${comment}\n`
        } else {
            gcode += `${text} \n`
        }
    }

    const addCommand = (command: string, params: { [index: string]: string | number } = {}) => {
        _.forOwn(params, (value, key) => {
            command += ' ' + _.toUpper(key) + '' + value
        })
        addLine(command)
    }

    // used for some calculations
    const filamentRadius = filamentDiameter / 2


    /* determines if there is enough space left to wind up the filament either in a trapezoid fashion
    * or in a inset square fashion.
    * uses trapezoid if after filling a complete layer with filament there is more or equal than half of filament width left
    * uses rectangle if there is not enough space left to let the filament sit properly inbetween

    * trapezoid:
    *   |* * * * * * |
    *   | * * * * * *|  odd
    *   |* * * * * * |  even


    * rectangle:
    *   |* * * * * *|
    *   | * * * * * |  odd
    *   |* * * * * *|  even
    * 
    */
    const rollTrapezoid = (spoolInnerWidth % filamentDiameter) >= filamentRadius

    const revsPerLayerEven = _.floor(spoolInnerWidth / filamentDiameter)

    // subtract one rev of filament layering from every n-odd layer if using rectangle layering
    // we start at layer 0
    const revsPerLayerOdd = revsPerLayerEven - (rollTrapezoid ? 0 : 1)

    // the height possible to be spooled up
    // this essentially is a "volume rectangle" thats enough to anneal the spools volume to be filled in 2D, no need for 3D
    const layeringHeight = (spoolOuterDiameter - spoolCoreDiameter) / 2
    const spoolCoreRadius = spoolCoreDiameter / 2
    const spoolCoreRadiusCorrected = spoolCoreRadius + filamentRadius
    //the first layers average filament center height relative to spool-center 
    // starts at half the filament width. every consecutive layer gets added the
    // tightpacking corrected filament height
    // see http://hydra.nat.uni-magdeburg.de/packing/csq/csq141.html
    // if the filaments perfectly touch every n+1 layers core center height is only half the filament-diameter-width 
    // higher than the previous layer. we assume .87 to account for slippage
    const tightpackingCorrectedLayerHeight = _.round(filamentDiameter * tightpackingCorrection, 5)


    // the height of the volumetric rectangle to actually fill. we measure from the filament center, so we have to 
    // subtract filament radius *2 (one on bottom one on top)
    const volumetricHeightToFill = layeringHeight - filamentDiameter

    // the max amount of layers possible on the spool. we just bruteforce us through every layer in the loop down below and solve it iteratively
    const noLayers = Math.floor(volumetricHeightToFill / tightpackingCorrectedLayerHeight)

    // filamentRadius in mm squared by one meter in mm length times the filament density in mm3
    const filamentWeightPerMeter = _.round(filamentRadius ** 2 * Math.PI * 1000 * (filamentDensity / 1000), 5)


    let desiredGrams = 0
    let desiredMeters = 0

    if (desiredAmountUnit === 'g') {
        desiredGrams = desiredAmount
        desiredMeters = _.round(desiredGrams / filamentWeightPerMeter, 5)
    }

    if (desiredAmountUnit === 'm') {
        desiredMeters = desiredAmount
        desiredGrams = _.round(desiredMeters * filamentWeightPerMeter, 5)
    }

    // datastructure to store our layer information for gcode generation later
    let layerData: Array<{
        index: number
        radiusOfFilamentCenter: number
        stepperStart: number
        stepperEnd: number
        revs: number
        lengthOnLayer: number
        gramsOnLayer: number
        ratioLayerUsed: number
        revsSteps: number
        revExtrusionValue: number
        feedrate: number
    }> = []

    let gramsUpperBound = 0
    let metersUpperBound = 0

    let metersLeft = desiredMeters

    for (let i = 0; i < noLayers; i += 1) {


        const thisLayersFilamentCenterRadius = spoolCoreRadiusCorrected + i * tightpackingCorrectedLayerHeight

        // depending on the spooling style we have known numbers for a full filling of revs at even or odd layer indices
        const isEvenLayer = i % 2 === 0
        const revsOnThisLayer = isEvenLayer ? revsPerLayerEven : revsPerLayerOdd

        // length of filament on layer if layer is fully filled (2 * pi * radius * revs possible)
        const lengthOnLayer = 2 * Math.PI * thisLayersFilamentCenterRadius / 1000 * revsOnThisLayer

        // calculate feedrate ratio before length on layer is truncated/adjusted
        // this uses the maximum amount spoolable to get the factor to the prev layer. its accurate enough
        let feedrate = maximumSpeed
        if (i > 0) {
            feedrate = maximumSpeed * layerData[0].radiusOfFilamentCenter / thisLayersFilamentCenterRadius
        }

        // how heavy all revs on a layer together 
        const gramsOnLayer = lengthOnLayer * filamentWeightPerMeter

        // assign the incrementors with their absolute value
        gramsUpperBound += gramsOnLayer
        metersUpperBound += lengthOnLayer

        // how much of the layer should be wound. 0 = not at all, 1= completely
        let ratioLayerUsed = 1
        // check if the current rev can be completely wound or has to be stopped half way
        if (metersLeft < 0) {
            ratioLayerUsed = 0
        } else if ((metersLeft - lengthOnLayer) < 0) {
            ratioLayerUsed = _.round(metersLeft / lengthOnLayer, 5)
            metersLeft -= lengthOnLayer
        } else {
            // ratio layer used stays 1
            metersLeft -= lengthOnLayer
        }

        // we start all the even layers at 0+filament radius (the leftest point)
        // we start all the odd layers at the maximum fill to the right (spoolInnerDiameter - filament radius)
        // the odd layers are corrected depending if we use trapezoid or rectangle spooling
        // the way to go will then be capped by the % of layer used after we established difference to the prev layer
        let stepperStart = 0
        let stepperEnd = 0


        /* the adjustment for trapezoid / rectangle layering is done via the revs variable here */
        //from left to right 
        if (isEvenLayer) {
            stepperStart = filamentRadius
            stepperEnd = filamentRadius + filamentDiameter * revsOnThisLayer
        }
        // from right to left
        if (!isEvenLayer) {
            stepperStart = filamentRadius * 2 + filamentDiameter * revsOnThisLayer
            stepperEnd = filamentRadius * 2
        }

        if (ratioLayerUsed < 1) {
            // now correct the endpoints if we are on the last layer used
            // left to right
            let difference = Math.abs(stepperStart - stepperEnd)
            difference *= ratioLayerUsed

            if (isEvenLayer) {
                stepperEnd = stepperStart + difference
            }

            if (!isEvenLayer) {
                stepperEnd = stepperStart - difference
            }
        }





        // save all the values adjusted for the actual amount of layer used
        layerData[i] = {
            index: i,
            stepperStart: stepperStart,
            stepperEnd: stepperEnd,
            radiusOfFilamentCenter: thisLayersFilamentCenterRadius,
            revs: revsOnThisLayer * ratioLayerUsed,
            revsSteps: _.round(revsOnThisLayer * ratioLayerUsed * unitsPerSpoolRev, 6),
            lengthOnLayer: lengthOnLayer * ratioLayerUsed,
            gramsOnLayer: gramsOnLayer * ratioLayerUsed,
            ratioLayerUsed: ratioLayerUsed,
            revExtrusionValue: (layerData[i - 1]?.revExtrusionValue || 0) + unitsPerSpoolRev * revsOnThisLayer * ratioLayerUsed,
            feedrate: feedrate
        }


    }


    gramsUpperBound = _.round(gramsUpperBound, 5)
    metersUpperBound = _.round(metersUpperBound, 5)

    console.log(layerData)

    addComment('Plasmics Respooler GCode')
    addComment()
    addComment('Parameters:')
    addComment()
    _.forOwn(params, (value, key) => {
        addComment(`${key}: ${value}`)
    })
    addComment()
    addComment('Derived Values:')
    addComment()
    addComment('filamentRadius: ' + filamentRadius)
    addComment('noLayers: ' + noLayers)
    addComment('tightpackingCorrectedLayerHeight: ' + tightpackingCorrectedLayerHeight)
    addComment('trapezoid Layering: ' + rollTrapezoid)
    addComment('rectangle Layering: ' + !rollTrapezoid)
    addComment('filamentWeightPerMeter: ' + filamentWeightPerMeter + ' grams')
    addComment('revsPerLayerEven: ' + revsPerLayerEven)
    addComment('revsPerLayerOdd: ' + revsPerLayerOdd)
    addComment()
    addComment('desiredGrams: ' + desiredGrams)
    addComment('desiredMeters: ' + desiredMeters)
    addComment('metersUpperBound: ' + metersUpperBound)
    addComment('gramsUpperBound: ' + gramsUpperBound)


    addLine()

    addComment('homing spool')
    addLine('MANUAL_STEPPER STEPPER=my_stepper_x SET_POSITION=100 MOVE=-100 STOP_ON_ENDSTOP=1')
    addLine()


    for (let layer of layerData) {
        // stop if we do not need the layers anymore
        if (layer.ratioLayerUsed <= 0) break


        addComment(`layer index ${layer.index} used by ${_.round(layer.ratioLayerUsed * 100, 2)}% `)
        addComment(`${_.round(layer.gramsOnLayer, 3)}g and ${_.round(layer.lengthOnLayer, 3)}m of filament on this layer`)
        addComment(`noRevs on layer: ${layer.revs}, feedrate: ${layer.feedrate}`)
        addCommand('MANUAL_STEPPER', { 'STEPPER=': 'my_stepper_x', 'MOVE=': layer.stepperStart, 'SYNC=': 1 })
        addCommand('G1', { e: 0, f: 0 })
        addCommand('MANUAL_STEPPER', { 'STEPPER=': 'my_stepper_x', 'MOVE=': layer.stepperEnd, 'SYNC=': 0 })
        addCommand('G1', { e: layer.revExtrusionValue, f: _.round(layer.feedrate, 5) })



        addLine()
    }

    return gcode
}

export const GCodeGenerator: React.FC = () => {

    const respoolerParameters = useRecoilValue(getRespoolerParametersAtom({}))

    const [gCode, setGCode] = useState('')
    useEffect(() => {
        setGCode(generateGCode(respoolerParameters))
    }, [respoolerParameters])

    return <Stack spacing={2}>
        <Card variant="outlined">
            <CardContent style={{ paddingBottom: 2 }} sx={{ p: 1 }} >
                <TextareaAutosize
                    className="no-outline-focus"
                    style={{ overflow: 'auto', color: '#eee', border: 0, width: '100%', height: '100%', minHeight: '450px', background: 'transparent' }}
                    maxRows={30}
                    placeholder="gcode goes here"
                    value={gCode}
                />
            </CardContent>
        </Card>
    </Stack>
}
