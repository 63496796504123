import { Card, CardActions, Button, Collapse, Stack, TextField, InputAdornment } from "@mui/material"
import React from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import { getRespoolerParametersAtom } from './RespoolerParameters'
import { useRecoilState } from "recoil"
export interface InternalParameters {
    filamentDensity: number
    unitsPerSpoolRev: number
    maximumSpeed: number
    tightpackingCorrection: number
}


export const internalParameterDefaults: InternalParameters = {
    filamentDensity: 1.19,
    unitsPerSpoolRev: 200,
    maximumSpeed: 1000,
    tightpackingCorrection: .87
}


export const InternalParameters = () => {

    const [internalParametersExpanded, setInternalParametersExpanded] = React.useState(false)
    const [parameters, setParameters] = useRecoilState(getRespoolerParametersAtom({}))

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setParameters(prev => {
            return {
                ...prev,
                [e.target.name]: e.target.value
            }
        })
    }

    return <Card variant="outlined">
        <CardActions style={{ padding: 0 }}>
            <Button
                style={{ justifyContent: 'start', width: '100%', color: '#fff' }}
                onClick={() => {
                    setInternalParametersExpanded(prev => !prev)
                }}
                startIcon={internalParametersExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
                {internalParametersExpanded ? "Hide Variables" : "Change Formula Variables"}
            </Button>
        </CardActions>

        <Collapse in={internalParametersExpanded} timeout="auto">
            <Stack style={{ padding: 10 }} spacing={1}>
                <TextField
                    label="Filament Density"
                    variant="standard"
                    name="filamentDensity"
                    onChange={handleChange}
                    value={parameters.filamentDensity}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">g / cm3</InputAdornment>,
                    }}
                />
                <TextField
                    label="Units p. Spool Rev."
                    variant="standard"
                    name="unitsPerSpoolRev"
                    onChange={handleChange}
                    value={parameters.unitsPerSpoolRev}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">unit</InputAdornment>,
                    }}
                />
                <TextField
                    label="Max Feedrate"
                    variant="standard"
                    name="maximumSpeed"
                    onChange={handleChange}
                    value={parameters.maximumSpeed}
                    InputProps={{
                        endAdornment: <InputAdornment position="start">unit</InputAdornment>,
                    }}
                />

                <TextField
                    label="Tightpacking Correction"
                    variant="standard"
                    name="spoolOuterDiameter"
                    onChange={handleChange}
                    value={parameters.tightpackingCorrection}
                />
            </Stack>
        </Collapse>
    </Card>
}