import React from 'react'
import ReactDOM from 'react-dom'
import { Respooler } from './Respooler'
import {
    RecoilRoot,
} from 'recoil'

ReactDOM.render(
    <React.StrictMode>

        <Respooler />

    </React.StrictMode>,
    document.getElementById('root')
)
